import { get, put, Delete, post } from "../axios";

// 科目管理列表
export const subjectList = (params) => get(`/web/course/subject/list`, params);

// 科目新增
export const subjectAdd = (params) => post(`/web/course/subject/add`, params);

// 科目编辑
export const subjectEdit = (params) => put(`/web/course/subject/edit`, params);

// 科目删除
export const subjectDelete = (params) =>
  Delete(`/web/course/subject/delete/` + params, "");

// 科目-书目管理列表
export const subjectBookList = (params) =>
  get(`/web/course/subject/book/list`, params);

// 书目新增
export const subjectBookAdd = (params) =>
  post(`/web/course/subject/book/add`, params);

// 书目编辑
export const subjectBookEdit = (params) =>
  put(`/web/course/subject/book/edit`, params);

// 科目删除
export const subjectBookDelete = (params) =>
  Delete(`/web/course/subject/book/delete/` + params, "");
